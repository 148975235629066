import React, { useState, useEffect, useRef } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import userIcon from '../../assets/user-icon.svg';
import './User.css';
import { useAuth0 } from '@auth0/auth0-react'
import ProjectMenu from './ProjectMenu';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

function User({logout, projectSelected, resetViz, permissions}) {
    const {user} = useAuth0()
    // console.log(permissions);
    // let userPerm = user['https://www.bioinfox.com/permissions'].permissions;
    // console.log(typeof(userPerm))
    let testArr = []
    permissions.forEach((perm) => {
        let tempObj = {'tableau': false}
        let permSplit = perm.split(':')
        permSplit.forEach((string) => {
            if (string.includes('hospital')) {
                tempObj['name'] = string;
            } else if (string.includes('admin')) {
                tempObj['admin'] = true;
            } else if (string.includes('view')) {
                tempObj['admin'] = false
            } else if (string.includes('license')) {
                tempObj['tableau'] = true
            } else {
                tempObj['label'] = string
            }
        })
        // console.log(tempObj);
        testArr.push(tempObj)
    });
    // console.log(testArr);
    
    // change to use HOF instead of a for loop, looking to use map as well as forEach
    // will split all the permissions then from those new arrays, check conditional to set the object keys correctly
    // input - array of permissions (strings); output - new array of object permissions
    // userPerm.forEach((perm) => {
    //     // console.log(perm);
    //     let tempObj = {};
    //     tempObj['tableau'] = false;
    //     let temp = perm.split(':')
    //     // console.log(temp)
    //     temp.forEach((string) => {
    //         // console.log(string)
    //         if (string.includes('hospital')) {
    //             tempObj['name'] = string;
    //         } else if (string.includes('admin')) {
    //             tempObj['admin'] = true;
    //         } else if (string.includes('view')) {
    //             tempObj['admin'] = false
    //         } else if (string === 'license') {
    //             tempObj['tableau'] = true;
    //         } else {
    //             tempObj['label'] = string
    //         }
    //     })
    //     // console.log(tempObj);
    //     testArr.push(tempObj);
    // })
    // console.log(testArr)
    testArr.sort((a, b) => {
        let valA = parseInt(a.name.slice(8))
        let valB = parseInt(b.name.slice(8))
        // console.log(valA, valB);
        return valA - valB
    })
    // console.log(testArr)
    const [role, setRole] = useState(testArr[0]);

    const prevRole = usePrevious(role);
    useEffect(() => {
        if (prevRole !== role) {
            projectSelected(role);
        }
        // resetViz()
    }, [projectSelected, role, prevRole]);

    const switchProject = (selection) => {
        // console.log('User.js: ' + selection)
        setRole(selection);
        // resetViz();
        // projectSelected(selection);
    }

    // console.log(user);

    return (
        <div className="userInfo">
            <ProjectMenu 
                // roles={user['https://www.bioinfox.com/app_metadata'].roles}
                roles={testArr.length >= 1 ? testArr : [{ name: 'hospital1', label: 'Demo', admin: false, tableau: false }] }
                switchProject={switchProject}
                resetViz={resetViz}
            />
            <Popup 
                trigger={<img src={userIcon} alt="user-icon" className="user-icon" />}
                content={
                    <div>
                        <Button 
                            onClick={logout}
                            color="red"
                            content="Log Out"
                        />
                    </div>
                }
                on="click"
            />
            <div className="project-info" >
                {user.email}
                <br />
                {role ? `Project: ${role.label}` : '' }
            </div>
        </div>
    )
}

export default User
