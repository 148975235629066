import React, { useState, useEffect } from 'react'
import './DownloadFiles.css';
import Button from 'react-bootstrap/Button';
import { downloadFiles, checkMeta, downloadList, downloadMeta } from '../../../Helpers';
import { useAuth0 } from '@auth0/auth0-react';
import InfoAlert from '../../Alerts/InfoAlert'
import Spinner from 'react-bootstrap/Spinner'
import DownloadList from './DownloadList';
let outsideSetFasta;
let outsideSetTre;
let outsideSetFiltered;

export function checkFileFasta(bool) {
    outsideSetFasta(bool);
}
export function checkFileTre(bool) {
    outsideSetTre(bool);
}
export function checkFileFiltered(bool) {
    outsideSetFiltered(bool);
}
let outsideSetAdditonal;
export function showAdd(bool) {
    outsideSetAdditonal(bool);
}
let outsideSetStandard
export function showStandard(bool) {
    outsideSetStandard(bool);
}
let outsideSetSequence
export function showSequence(bool) {
    outsideSetSequence(bool);
}

function DownloadFiles({ show, hospital, admin, dataType }) {
    const [fasta, setFasta] = useState(false);
    const [tre, setTre] = useState(false);
    const [filtered, setFiltered] = useState(false);
    // const [usaGIS, setUsaGIS] = useState(true);
    // const [globalGIS, setGlobalGIS] = useState(true);
    const [additional, setAdditional] = useState(false)
    const [standard, setStandard] = useState(false);
    const [sequence, setSequence] = useState(false);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [available, setAvailable] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    outsideSetTre = setTre;
    outsideSetFasta = setFasta;
    outsideSetFiltered = setFiltered;
    outsideSetAdditonal = setAdditional
    outsideSetStandard = setStandard;
    outsideSetSequence = setSequence;
    
    useEffect(() => {
        // checkAddFile(hospital);
        // checkStandard(hospital);
        let token;
        (async () => {
        token = await getAccessTokenSilently();
        // checkFile(hospital, token, setLoading);
        checkMeta(hospital, token, setLoading, dataType);
        downloadList(hospital, token, setAvailable, 'output');
        })()
        // checkSequence(hospital);
    },[hospital, getAccessTokenSilently, dataType]);

    if (!show) {
        return null;
    }

    // const downloadSampleTemp = async () => {
    //     let token = await getAccessTokenSilently();
    //     getTemplates(hospital, token, 'sample', 'samples')
    // }

    // const downloadSeqTemp = async () => {
    //     let token = await getAccessTokenSilently();
    //     getTemplates(hospital, token, 'sequence', 'samples')
    // }

    const downloadFilesAll = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadFiles(hospital, token, bucket, key);
        setAlert(true);
    }

    const downloadMetaFile = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadMeta(hospital, token, bucket, key);
        setAlert(true);
    }

    return (
        <div className="downloadFiles" >
            {loading ? 
                <Spinner animation='border' role="status" variant='primary' /> : null
            }
            <InfoAlert 
                showAlert={alert}
                setAlert={setAlert}
            />
            {/* <h3>Download Files</h3> */}
            {/* <div className="projectDown">
                <h3 className="title">Download Project Analysis Files</h3>
                {(fasta === false && tre === false && filtered === false) || admin === false ? 
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {fasta === true && admin === true ? 
                    <Button className="download-btn" variant="primary" onClick={() => downloadFilesAll('output', 'fasta')} >Download Fasta File</Button> : null
                }
                {filtered === true && admin === true ?
                    <Button className="download-btn" variant='primary' onClick={() => downloadFilesAll('output', 'filtered')} >Download HQ-Fasta File</Button> : null
                }
                {tre === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadFilesAll('output', 'tre')} >Download Tree File</Button> : null
                }
            </div> */}
            <div className="projectMeta">
                <h3 className="title">Download Sample Metadata File</h3>
                {standard === false || admin === false ? 
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {standard === true && admin === true ?
                    <Button className="hosp_temp" variant="primary" onClick={() => downloadMetaFile('meta', 'sample')} >Sample Metadata</Button> : null
                    // <Button className="hosp_temp" variant="primary" onClick={() => getHospTemp(hospital)} >Sample Metadata (Standard)</Button> : null
                }
                
            </div>
            <div className="projectMeta" >
                <h3 className="title">Download Quality Metadata File</h3>
                {additional === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {additional === true && admin === true ? 
                    <Button className="add-btn" variant="primary" onClick={() => downloadMetaFile('meta', 'quality')} >Quality Metadata</Button> : null 
                }
            </div>
            <div className="sequenceMeta" >
                <h3 className="title">Download Sequence Metadata File</h3>
                {sequence === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {sequence === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadMetaFile('meta', 'sequence')} >Sequence Metadata</Button> : null 
                }
            </div>
            {/* add new download template function and pass proper parameters */}
            {/* <div className="template">
                <h3 className="title">Download Template Metadata File</h3>
                <Button className="template-btn" variant="primary" onClick={downloadSampleTemp} >Sample Metadata (Template)</Button>
                <Button className="template-btn" variant="primary" onClick={downloadSeqTemp} >Sequence Metadata (Template)</Button>
            </div> */}
            <DownloadList 
                available={available}
                downloadFilesAll={downloadFilesAll}
                dataType={dataType}
            />
            <br></br>
            <br></br>
        </div>
    )
}

export default DownloadFiles;