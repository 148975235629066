import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';

function ErrorAlert({ showError, setAlert }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(showError);
    }, [showError]);

    const handleSuccess = () => {
        setAlert(!show);
    }

    return (
        <div className="errorAlertMessage">
            {show ? 
            <Alert variant="danger" show={show} style={{ zIndex: 100, marginLeft: '15%', width: '75%'}} >
                <Alert.Heading style={{ textAlign: 'center', fontSize: '25px' }} >Error</Alert.Heading>
                <p style={{ textAlign: 'center', fontSize: '20px' }}>File was not uploaded, please email support at <b>support@bioinfox.com</b> if problem persists</p>
                <Button variant='danger' onClick={handleSuccess} >Close</Button>
            </Alert> : null }
        </div>
    )
}

export default ErrorAlert