import React from 'react';
import logo from '../assets/FoxSeq_logo-1.png';
import Copyright from '../components/Footer/Copyright';
import { Button } from 'semantic-ui-react';
import "./LandingPage.css";
// import cloud from '../assets/Cloudticity Logo_color.svg';
// // import sbir from '../assets/SBIR_logo2-1.jpg';
// import awsD from '../assets/AWS-DDI.png';
// import nsf from '../assets/nsf.svg';

function LandingPage({show, closeLanding}) {
    // if (!show) {
    //     // console.log(show);
    //     return null;
    // }

    return (
        <div style={{
            position: 'absolute',
            top: 50,
            left: 0,
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            pointerEvents: "all",
            zIndex: 100,
            fontFamily: 'Avenir',
            alignContent: 'center',
            display: 'inline-block',
            textAlign: 'center'
            // placeItems: 'center'
        }}>
            <img src={logo} alt="" style={{
                width: '525px',
                height: '400px',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}/>
            <h3 style={{
                marginTop: '5%',
                alignItems: "center",
                // marginLeft: 'calc(100% - 60%)',
                textAlign: 'center',
                fontFamily: 'Avenir',
                fontSize: 'xx-large'
            }}>Welcome to FoxSeq®</h3>
            <p style={{
                textAlign: 'center',
                alignItems: 'center',
                fontFamily: 'Avenir',
                fontSize: 'large'
            }}>Cloud-based Bioinformatics Software Services</p>
            {/* <button style={{
                backgroundColor: 'rgb(255, 80, 0)', //#ffad33 rgb(255, 115, 0)
                position: 'absolute',
                bottom: '150px',
                // right: '46%',
                left: '46%',
                // top: '80%',
                display: 'grid',
                placeItem: 'center',
                // margin: '0 auto',
                // marginTop: 'calc(100vh - 40%)',
                alignItems: 'center',
                alignContent: 'center',
                height: '75px',
                width: '200px',
                fontWeight: 'bold',
                fontSize: '1.3em',
                color: 'black',
                textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white',
                borderRadius: '12px',
                cursor: 'pointer'
            }} onClick={() => closeLanding()}>Enter FoxSeq®</button> */}
            <Button className="foxseq-btn" color='orange' size='huge' style={{marginTop: '100px', marginBottom:'50px'}} onClick={() => closeLanding()} >Enter FoxSeq®</Button>
            {/* <div style={{
                position: 'absolute',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                // display: 'absolute',
                // width: '100%',
                // marginLeft: '-250px',
                // marginRight: 'auto',
                left: 'calc(100vw - 66vw)',
                bottom: '100px'
            }} >
                <img src={cloud} alt='cloud' style={{ width: '20%', marginLeft: '1%', marginRight: '1%'  }} />
                <img src={awsD} alt='awsD' style={{ width: '20%', marginLeft: '1%', marginRight: '1%' }} />
                <img src={nsf} alt='nsf' style={{ width: '20%', marginLeft: '1%', marginRight: '1%' }} />
            </div> */}
            <Copyright />
        </div>
    )
}

export default LandingPage
