import React from 'react'
import './Copyright.css'
import Cloudticity from '../../assets/Cloudticity Logo_color.svg';
// import nsf from '../../assets/nsf.svg';
// import sbir from '../../assets/SBIR_logo2-1.jpg';

function Copyright() {
    return (
        <div className='copyright'>
            <img src={Cloudticity} alt='cloud' className='logoImg' />
            <div className="copyright-txt">The FoxSeq® service mark and logo are registered trademarks owned by BioInfoExperts LLC</div>
            {/* <img src={nsf} alt='nsf' className='logoImgNsf' /> */}
            {/* <img src={sbir} alt='sbir' className='logoImgSbir' /> */}
        </div>
    )
}

export default Copyright
