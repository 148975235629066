import React, { useState, useEffect } from 'react'
import { Checkbox, Popup } from "semantic-ui-react";
import './Header.css';
import menuIcon from '../../assets/ic_menu_black_24px.svg';
import logo from '../../assets/FoxSeq_logo-1.png'
import User from './User';
import { useAuth0 } from '@auth0/auth0-react';
import { getPermissions } from '../../Helpers.js';

function Header({ dataTypes, logout, useDataType, initDataType, projectSelected, resetViz }) {
    const [selection, setSelection] = useState(initDataType);
    const [permissions, setPermissions] = useState([])
    const {getAccessTokenSilently} = useAuth0();

    const handleSelection = (selection) => {
        // console.log(selection.target.id);
        setSelection(selection.target.id);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDataType(selection.target.id);
        resetViz();
    }

    useEffect(() => {
      const getUserMetadata = async () => {
        const token = await getAccessTokenSilently();
        getPermissions(token, setPermissions);
      }
      getUserMetadata()
    }, [getAccessTokenSilently])
    

    return (
        <React.Fragment>
            <div className="header" >
                <div className="menu">
                    <Popup 
                        className="pathogen-selector"
                        trigger={<span><img src={menuIcon} alt="menu" className="menuIcon" /></span>}
                        content={<div className="pathogen-selector-toggle">
                            {dataTypes.map((datatype) => {
                                return (
                                    <div key={datatype.label} >
                                    <Checkbox toggle
                                        className="toggle-checkbox"
                                        id={datatype.label}
                                        // label={datatype.label}
                                        value={datatype.label}
                                        key={datatype.label}
                                        checked={selection === datatype.label}
                                        onClick={handleSelection}
                                        type="checkbox"
                                    />
                                    {datatype.label}
                                    </div>
                                )
                            })}
                        </div>}
                        on='click'
                        position="bottom left"
                    />
                    <img src={logo} alt="logo" className="logo" />
                </div>
                <div className="user" >
                    <User 
                        logout={logout}
                        projectSelected={projectSelected}
                        resetViz={resetViz}
                        permissions={permissions}
                    />
                </div>
            </div>    
        </React.Fragment>
    )
}

export default Header
