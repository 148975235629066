import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import './DownloadFiles.css';
import { useAuth0 } from '@auth0/auth0-react';
import { getTemplates, checkFileBac, downloadFiles, checkMeta, downloadMeta, downloadList } from '../../../Helpers';
import Spinner from 'react-bootstrap/Spinner'
import DownloadList from './DownloadList';

// import { checkAddFileBac, checkStandardBac, checkSequenceBac, getHospTempBac, hospAddFileBac, getSequenceBac, getTemplateBac, getSeqTemplateBac } from '../../../Helpers';

let outsideSetAdditonalBac;
export function showAddBac(bool) {
    outsideSetAdditonalBac(bool);
}
let outsideSetStandardBac
export function showStandardBac(bool) {
    outsideSetStandardBac(bool);
}
let outsideSetSequenceBac
export function showSequenceBac(bool) {
    outsideSetSequenceBac(bool);
}

function DownloadBacteria({show, hospital, admin, dataType}) {
    // const [fasta, setFasta] = useState(false);
    // const [tre, setTre] = useState(false);
    // const [usaGIS, setUsaGIS] = useState(true);
    // const [globalGIS, setGlobalGIS] = useState(true);
    const [additional, setAdditional] = useState(false)
    const [standard, setStandard] = useState(false);
    const [sequence, setSequence] = useState(false);
    const [loading, setLoading] = useState(true);
    const [available, setAvailable] = useState([])
    const {getAccessTokenSilently} = useAuth0();

    // outsideSetTre = setTre;
    // outsideSetFasta = setFasta;
    outsideSetAdditonalBac = setAdditional
    outsideSetStandardBac = setStandard;
    outsideSetSequenceBac = setSequence;
    
    
    useEffect(() => {
        // checkAddFileBac(hospital);
        // checkStandardBac(hospital);
        // // checkFiles(hospital);
        // checkSequenceBac(hospital);
        let token;
        (async () => {
            token = await getAccessTokenSilently();
            // checkFileBac(hospital, token)
            checkMeta(hospital, token, setLoading, dataType)
            downloadList(hospital, token, setAvailable, 'outputBac');
        })()
    },[hospital, getAccessTokenSilently, dataType]);

    if (!show) {
        return null;
    }

    const downloadBacteriaFiles = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadFiles(hospital, token, bucket, key);
    }

    const downloadMetaFile = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadMeta(hospital, token, bucket, key);
    }

    const downloadBacteriaTemp = async (template) => {
        let token = await getAccessTokenSilently();
        getTemplates(hospital, token, template, 'samplesBac')
    }

    // const downloadFasta = () => {
    //     getFastaFile();
    // }
    
    // const downloadTre = () => {
    //     getTreFile();
    // }

    return (
        <div className="downloadFiles" >
            {/* <h3>Download Files</h3> */}
            {/* <div className="projectDown">
                <h3 className="title">Download Project Analysis Files</h3>
                {fasta === false && tre === false ? 
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {fasta === true ? 
                    <Button className="download-btn" variant="primary" onClick={downloadFasta} >Download Fasta File</Button> : null
                }
                {tre === true ?
                    <Button className="download-btn" variant="primary" onClick={downloadTre} >Download Tre File</Button> : null
                }
            </div> */}
            {loading ?
                <Spinner animation='border' role="status" variant='primary' /> : null
            }
            <div className="projectMeta">
                <h3 className="title">Download Bacteria Metadata File</h3>
                {standard === false || admin === false ? 
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {standard === true && admin === true ?
                    <Button className="hosp_temp" variant="primary" onClick={() => downloadMetaFile('bacMeta', 'bacteria')} >Bacteria Metadata</Button> : null
                }
            </div>
            <div className="projectMeta">
                <h3 className="title">Download Antibiotics Metadata File</h3>
                {additional === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {additional === true && admin === true ? 
                    <Button className="add-btn" variant="primary" onClick={() => downloadMetaFile('bacMeta', 'antibiotics')} >Antibiotics Metadata</Button> : null 
                }
            </div>
            <div className="sequenceMeta">
                <h3 className="title">Download Susceptibility Metadata File</h3>
                {sequence === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {sequence === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadMetaFile('bacMeta', 'suscept')} >Susceptibility Metadata</Button> : null
                }
            </div>
            <DownloadList 
                available={available}
                downloadFilesAll={downloadBacteriaFiles}
                dataType={dataType}
            />
            <br></br>
            <br></br>
            {/* <div className="template">
                <h3 className="title">Download Blank Template Metadata Files</h3>
                <Button className="template-btn" variant="primary" onClick={() => downloadBacteriaTemp('sample')} >New Template (Sample)</Button>
                <Button className="template-btn" variant="primary" onClick={() => downloadBacteriaTemp('sequence')} >New Template (Sequence)</Button>
            </div> */}
            {/* <div className="gisaidDown">
                <h3 className="title">Download GISAID Files</h3>
                {usaGIS ? 
                    <Button className="download-btn" variant="primary" onClick={getGisaidUsa} >Download GISAID Metadata (USA)</Button>
                    : null
                }
                {globalGIS ?
                    <Button className="download-btn" variant="primary" onClick={getGisaidGlobal} >Download GISAID Metadata (global)</Button>
                    : null
                }
            </div> */}
        </div>
    )
}

export default DownloadBacteria
