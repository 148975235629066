import React, { useRef, useEffect } from 'react'
import { TableauViz } from 'tableau-react';
// import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
// import { TableauViz } from 'https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'
// import tableau from 'tableau-api';

// const { tableau } = window;
// const viz = document.getElementById('tableauViz');

function Tableau({ show, url }) {
  // const [display, setDisplay] = useState(show);

  // const ref = useRef(null);

  // useEffect(() => {
  //   const viz = new tableau.Viz(ref.current, url, {
  //     device: 'desktop',
  //     toolbarPosition: 'TOP',
  //     width: '100%',
  //     height: '81vh'
  //   })
  // }, [url])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const initViz = () => {
  //   new tableau.Viz(ref.current, url, {
  //     device: 'desktop',
  //     toolbarPosition: 'TOP',
  //     width: '100%',
  //     height: '81vh'
  //   })
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(initViz, []);

  if (!show) {
    return null;
  }

  return (
    // <div ref={ref} />
    <tableau-viz
      id='tableauViz'
      src={url}
      device='desktop'
      toolbar='top'
      width='100%'
      height='81vh'
    ></tableau-viz>
  )
}

export default Tableau
