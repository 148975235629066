import { getUrls } from './pages/Pages';
import { checkFileFasta, checkFileTre, showAdd, showStandard, showSequence, checkFileFiltered } from './components/Visualizations/DownloadFiles/DownloadFiles';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { showAddBac, showSequenceBac, showStandardBac } from './components/Visualizations/DownloadFiles/DownloadBacteria';

// const AWS = require('aws-sdk');
const fileDownload = require('js-file-download');
// const fs = require("file-system")

const baseUrl = "https://mqq8q8nfr8.execute-api.us-east-2.amazonaws.com/dev"  // prod
// const baseUrl = "https://rrn2qagewf.execute-api.us-east-2.amazonaws.com/dev"  // dev




// let nextSeqToken = '';
// let nextUploadToken = '';
// let nextDownloadToken = ''

// let logParams = {
//   logGroupName: 'FoxSeq-logging',
//   logStreamName: 'test'
// }

// logs.createLogStream(logParams, function (err, data) {
//   if (err) console.log(err, err.stack);
//   else console.log(data);
// });

// console.log(AWS.config.credentials)

let hospital = 'hospital1';
const getProject = (input, token) => {
  // console.log(input);
  if (input === null) return
  hospital = input.name;
  // console.log(hospital)
  getLinks(hospital, token);
  // checkFile(hospital, token);
}

const sHospHelp = (hosp) => {
  let store = hosp.slice(8);
  return 'H' + store;
}

const getPermissions = async (token, func) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  let url = `${baseUrl}/get_permissions`
  await axios.get(url, config)
    .then(res => {
      func(res.data);
    })
    .catch((error) => {
      console.log('Error in getting permissions');
      console.error(error);
    });
}

// let counter = 0;
// const getSeqToken = (group, stream, token, func, userHosp) => {
//   logs.describeLogStreams({
//     logGroupName: group,
//     logStreamNamePrefix: stream
//   }, function (err, data) {
//     if (err) {
//       console.error(err)
//     } else {
//       counter++
//       console.log(counter);
//       if (counter >= 2) {
//         return
//       }
//       console.log(data.logStreams[0].uploadSequenceToken);
//       token = data.logStreams[0].uploadSequenceToken;
//       console.log(token);
//     }
//   })
// }



const getLinks = async (hospital, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital: hospital
    }
  };
  // console.log(config);
  let url = `${baseUrl}/links`
  await axios.get(url, config)
    .then(res => {
      // console.log(res)
      // console.log(res.data)
      getUrls(res.data);
    })
    .catch(err => {
      console.error(err);
      console.error(err.response.status)
      // logError('FoxSeq-logging', 'tableau-link', err)
    })
}

const uploadFiles = (file, token, bucket, key) => {
  // console.log(file);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': file[0].type
    },
    params: {
      hospital: hospital,
      bucket: bucket,
      key: key,
      'file_name': key.split('/')[1]
    }
  }
  // console.log(config)
  let url = `${baseUrl}/file_upload` // need to figure out the upload end point
  // let wb = XLSX.read(file);
  // let fileContent = JSON.stringify(wb)
  // let blob = new Blob([file], { type: file[0].type })
  // console.log(blob)
  axios.post(url, file[0], config)
    .then(res => {
      // console.log(res);
    })
    .catch(err => {
      console.error(err);
      // logError('FoxSeq-logging', 'upload', err);
    })
}

const uploadLargeFiles = (file, token, bucket, key) => {
  // console.log(file);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': file[0].type
    },
    params: {
      hospital: hospital,
      bucket: bucket,
      key: key,
      'file_name': key.split('/')[1]
    }
  }
  // console.log(config);
  let url = `${baseUrl}/file_upload_node`;
  axios.get(url, config)
    .then(res => {
      // console.log(res);
      let preUrl = res.data.url;
      // console.log(res.data);
      // console.log(res.data.url)
      // console.log(file);
      // res.data.fields['content-type'] = file[0].type
      axios.put(preUrl, file[0])
        .then(res => {
          // console.log(res);
        })
        .catch(err => {
          console.error(err);
        })
    })
    .catch(err => {
      if (err.response) {
        console.error(err.response.data);
      }
      console.error(err);
    })
}



/* ############################ Download Helper Functions ############################# */

const downloadFiles = (hospital, token, bucket, key) => {
  let sHosp = sHospHelp(hospital);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      // responseType: 'arrayBuffer'
    },
    params: {
      hospital: hospital,
      bucket: bucket,
      key: key
    }
  }
  // console.log(config);
  let url = `${baseUrl}/file_download`
  axios.get(url, config)
    .then(res => {
      // console.log(res)
      if (key === 'sample' || key === 'custom' || key === 'sequence'){
        const wb = XLSX.read(res.data, { cellDates: true });
        // console.log(wb);
        let book = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true, cellDates: true, dateNF: "yyyy-mm-dd" });
        // console.log(book);
        // let preURL = res.data
        if (key === 'sample') {
          fileDownload(book, `SAMPLE_METADATA_${sHosp}.xlsx`)
        } else if (key === 'custom') {
          fileDownload(book, `CUSTOM_METADATA_${sHosp}.xlsx`)
        } else {
          fileDownload(book, `SEQUENCE_METADATA_${sHosp}.xlsx`)
        }
      } else if (key.includes('tre')) {
        // console.log(res);
        fileDownload(res.data, key);
      } else if (key.includes('fasta')) {
          if (res.headers['content-length'] >= 6000000) {
            let preUrl = res.data;
            axios.get(preUrl, { headers: { 'Content-type': 'application/fasta' }})
              .then(res => {
                // console.log(res);
                fileDownload(res.data, key)
              })
              .catch(err => {
                console.error(err);
              })
          } else {
            fileDownload(res.data, key)
          }
      } else {
        fileDownload(res.data, key)
      }
    })
    .catch(err => {
      console.error(err)
    })
}

const downloadMeta = async (hospital, token, bucket, key) => {
  let sHosp = sHospHelp(hospital);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital,
      bucket,
      key,
      sub: sHosp
    }
  }
  let url = `${baseUrl}/meta_download`;
  axios.get(url, config)
    .then(res => {
      const wb = XLSX.read(res.data, { cellDates: true });
      let book = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true, cellDates: true, dateMF: 'yyyy-mm-dd' });
      if (key === 'sample') {
        fileDownload(book, `SAMPLE_METADATA_${sHosp}.xlsx`)
      } else if (key === 'sequence') {
        fileDownload(book, `SEQUENCE_METADATA_${sHosp}.xlsx`)
      } else if (key === 'quality') {
        fileDownload(book, `QUALITY_METADATA_${sHosp}.xlsx`)
      } else if (key === 'bacteria') {
        fileDownload(book, `BACTERIA_METADATA_${sHosp}.xlsx`)
      } else if (key === 'antibiotics') {
        fileDownload(book, `ANTIBIOTICS_METADATA_${sHosp}.xlsx`)
      } else {
        fileDownload(book, `SUSCEPTIBILITY_METADATA_${sHosp}.xlsx`)
      }
    })
    .catch(err => {
      console.error(err);
    })
}

const downloadList = async (hospital, token, func, bucket) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital,
      bucket
    }
  }
  let url = `${baseUrl}/list_download`;
  await axios.get(url, config)
    .then(res => {
      func(res.data)
    })
    .catch(err => {
      console.error(err);
    })
}

// template parameter should have string to determine which template file to get
const getTemplates = async (hospital, token, template, bucket) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: 'arrayBuffer',
      'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    params: {
      hospital: hospital,
      template: template,
      bucket: bucket
    }
  }
  // console.log(config);
  let url = `${baseUrl}/gettemplate`
  await axios.get(url, config)
    .then(res => {
      // console.log(res);
      const wb = XLSX.read(res.data, { cellDates: true })
      // console.log(wb);
      let test = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true, cellDates: true })
      if (template === 'sample') {
        fileDownload(test, "SAMPLE_METADATA_template.xlsx");
      } else if (template === 'sequence') {
        fileDownload(test, "SEQUENCE_METADATA_template.xlsx")
      }
    })
    .catch(err => {
      console.error(err)
    })
}

const checkFile = async (hospital, token, func) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital: hospital
    }
  };
  // console.log(config);
  let url = `${baseUrl}/checkfile`;
  await axios.get(url, config)
    .then(res => {
      // console.log(res.data);
      let boolObj = res.data;
      checkFileFasta(boolObj['fasta']);
      checkFileTre(boolObj['tre']);
      checkFileFiltered(boolObj['filtered']);
      showAdd(boolObj['custom']);
      showStandard(boolObj['sample']);
      showSequence(boolObj['sequence']);
      func(false);
    })
    .catch(err => {
      console.error(err);
    })
}

const checkMeta = async (hospital, token, func, dataType) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital
    }
  }
  let url = `${baseUrl}/check_meta`;
  await axios.get(url, config)
    .then(res => {
      let boolObj = res.data
      console.log(boolObj);
      if (dataType === 'Virus') {
        showAdd(boolObj['quality']);
        showStandard(boolObj['sample']);
        showSequence(boolObj['sequence'])
      } else if (dataType === 'Bacteria') {
        showAddBac(boolObj['antibiotics'])
        showSequenceBac(boolObj['suscept'])
        showStandardBac(boolObj['bacteria'])
      }
      func(false);
    })
    .catch(err => {
      console.error(err);
    })
}

const checkFileBac = async (hospital, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      hospital: hospital
    }
  }
  let url = `${baseUrl}/checkfilebacteria`;
  await axios.get(url, config)
    .then(res => {
      // console.log(res.data);
      let boolObj = res.data;
      showAddBac(boolObj['custom']);
      showStandardBac(boolObj['sample']);
      showSequenceBac(boolObj['sequence']);
    })
    .catch(err => {
      console.error(err);
    })
}

/*######################################## Bacteria Download Helpers ############################################## */



export {
  getProject,
  getLinks,
  sHospHelp, 
  getTemplates,
  checkFile,
  downloadFiles,
  uploadFiles,
  uploadLargeFiles,
  checkFileBac,
  checkMeta,
  downloadList,
  downloadMeta,
  getPermissions
}