import React, { useState } from 'react'
import { Checkbox, Icon, Popup } from "semantic-ui-react";

function ProjectMenu({roles, switchProject, resetViz}) {
    const [selection, setSelection] = useState(roles[0])

    const changeProject = (selected) => {
        // console.log(selected)
        setSelection(selected);
        switchProject(selected);
        // resetViz()
    }

    return (
        <div>
            <Popup 
                    className="hospitalListPopup"
                    trigger={<span className="barsIconContainer"><Icon aria-label="SELECT" size="large" color="grey" name="product hunt" /></span>}
                    content={
                        <div className="hospitalListPopupMenu" style={{display: 'grid', margin: '2px'}}>
                            {roles.map((role) => {
                                return (
                                    <Checkbox 
                                        className="hospitalListPopupMenuCheckbox"
                                        id={role.name}
                                        label={role.label}
                                        key={role.label}
                                        onClick={() => changeProject(role)}
                                        toggle={true}
                                        checked={ selection.label === role.label }
                                    />
                                )
                            })}
                        </div>
                    }
                    on="click"
                    position="bottom center"
                />
        </div>
    )
}

export default ProjectMenu
