import React, { useRef, useState } from 'react'
import { sHospHelp, uploadLargeFiles } from '../../../Helpers';
import Button from 'react-bootstrap/Button';
// import Dropdown from 'react-bootstrap/Dropdown';
// import ProgressBar from 'react-bootstrap/ProgressBar'
import './UploadFiles.css';
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { useAuth0 } from '@auth0/auth0-react'


function UploadFiles({show, hospital, admin}) {
    const fileRef = useRef({});
    // const [selector, setSelector] = useState('Files');
    // const [fastqName, setFastqName] = useState('');
    // const [fastaName, setFastaName] = useState('');
    const [clientName, setClientName] = useState('');
    const [addName, setAddName] = useState('');
    // const [testName, setTestName] = useState('');
    // const [progress, setProgress] = useState(0)
    // const [showProgress, setShowProgress] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [seqMeta, setSeqMeta] = useState('');
    const [resMeta, setResMeta] = useState('')
    const [AMRMeta, setAMRMeta] = useState('')
    const {getAccessTokenSilently} = useAuth0()
    // console.log(admin);
    
    if (!show) {
        return null
    };

    if (!admin) {
        return (
        <div className='upload'>
            <h1>Uploads can only be done by Admins, please contact your system administrator.</h1> 
            <p>For additonal help contact us using the Contact Us button at the bottom of the screen or email support@bioinfox.com</p>
        </div>
        )
    }

    

    

    const fileClientSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        // let s3 = new AWS.S3();
        // setShowProgress(true);
        // let fileArr = fileRef.current['client'].files;
        // for (let i = 0; i < fileArr.length; i++) {
        //     s3.upload({
        //         Bucket: "bie-samples",
        //         Key: `${hospital}/SAMPLE_METADATA_${sHosp}.xlsx`,
        //         Body: fileArr[i],
        //         ServerSideEncryption: "AES256"
        //     }).on('httpUploadProgress', (evt) => {
        //         setProgress(Math.round((evt.loaded / evt.total) * 100))
        //     }).send((err, data) => {
        //         if (err) {
        //             console.error(err)
        //             setShowProgress(false);
        //             setAlert2(true);
        //             logError('FoxSeq-logging', 'upload', err);
        //             return
        //         }
        //         setAlert(true);
        //         setClientName('');
        //         setShowProgress(false)
        //     })
        // }
        let file = fileRef.current['client'].files;
        // console.log(file);
        let key = `${hospital}/SAMPLE_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key)
        setClientName('')
        setAlert(true);
    }

    const fileAddSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        // let s3 = new AWS.S3();
        // setShowProgress(true);
        // let fileArr = fileRef.current['add'].files;
        // for (let i = 0; i < fileArr.length; i++) {
        //     s3.upload({
        //         Bucket: 'bie-samples',
        //         Key: `${hospital}/CUSTOM_METADATA_${sHosp}.xlsx`,
        //         Body: fileArr[i],
        //         ServerSideEncryption: "AES256"
        //     }).on('httpUploadProgress', (evt) => {
        //         setProgress(Math.round((evt.loaded / evt.total) * 100))
        //     }).send((err, data) => {
        //         if (err) {
        //             console.error(err)
        //             setShowProgress(false);
        //             setAlert2(true);
        //             logError('FoxSeq-logging', 'upload', err);
        //             return
        //         }
        //         setAlert(true);
        //         setAddName('');
        //         setShowProgress(false)
        //     })
        // }
        let file = fileRef.current['add'].files;
        let key = `${hospital}/QUALITY_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key);
        setAddName('');
        setAlert(true);
    }

    // const onChange = (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         let fileArr = [];
    //         for (let i = 0; i < e.target.files.length; i++) {
    //             fileArr.push(e.target.files[i]);
    //         }
    //         setFastqName(fileArr);
    //     }
    //     // console.log(hospital);
    // }

    // const onChangeSeq = (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         setFastaName(e.target.files[0]);
    //     }
    // }

    const onChangeClient = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setClientName(e.target.files[0]);
        }
    }

    const onChangeAdd = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAddName(e.target.files[0]);
        }
    }

    const onChangeSeqMeta = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSeqMeta(e.target.files[0]);
        }
    }

    const onChangeRes = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setResMeta(e.target.files[0])
        }
    }

    const onChangeAMR = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAMRMeta(e.target.files[0])
        }
    }

    const fileSeqMetaSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently()
        // let fileArr = fileRef.current['seqMeta'].files;
        // const customBackoff = (retryCount) => {
        //     console.log(`retry count: ${retryCount}, waiting: 1000ms`)
        //     return 1000
        // }
        // setShowProgress(true);
        // for (let i = 0; i < fileArr.length; i++) {
        //     let s3 = new AWS.S3({region: 'us-east-1', maxRetries: 2, httpOptions: { connectTimeout: 20 * 1000, timeout: 60 * 1000}, retryDelayOptions: { customBackoff }});
        //     s3.upload({
        //         Bucket: 'bie-samples',
        //         Key: `${hospital}/SEQUENCE_METADATA_${sHosp}.xlsx`,
        //         Body: fileArr[i],
        //         ContentType: fileArr[i].type,
        //         ServerSideEncryption: "AES256"
        //     }).on('httpUploadProgress', (evt) => {
        //         setProgress(Math.round((evt.loaded / evt.total) * 100))
        //     }).send((err, data) => {
        //         if (err) {
        //             console.log(err);
        //             setShowProgress(false);
        //             setAlert2(true);
        //             logError('FoxSeq-logging', 'upload', err);
        //             return
        //         }
        //         console.log(data);
        //         setAlert(true);
        //         setSeqMeta('');
        //         setShowProgress(false)
        //     })
        // }
        let file = fileRef.current['seqMeta'].files;
        let key = `${hospital}/SEQUENCE_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key);
        setSeqMeta('');
        setAlert(true);
    }

    const fileResMetaSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        let file = fileRef.current['resMeta'].files;
        let key = `${hospital}/RESISTANCE_METADATA_${sHosp}.xlsx`;
        uploadLargeFiles(file, token, 'samples', key)
        setResMeta('');
        setAlert(true);
    }

    const fileAMRSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        let file = fileRef.current['amrMeta'].files;
        let key = `${hospital}/ANTIBIOTICS_METADATA_${sHosp}.xlsx`;
        uploadLargeFiles(file, token, 'samples', key);
        setAMRMeta('');
        setAlert(true);
    }

    return (
        <div className="upload" >
            <SuccessAlert 
                showSuccess={alert}
                setAlert={setAlert}
            />
            <ErrorAlert 
                showError={alert2}
                setAlert={setAlert2}
            />
            {/* <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold'}}>{`Upload Raw Fastq ${selector} to Pipeline`}</h3>
            <div className="selector_drop"> */}
                {/* <Dropdown className="selector">
                    <Dropdown.Toggle>{selector}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelector('Files')}>Files</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelector('Folders')}>Folders</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            {selector === 'Files' ? 
                <input className="input_fastq" type="file" accept=".fastq, .gz" multiple
                    ref={el => fileRef.current['fastq'] = el}
                    onChange={onChange}
                /> : 
                <input className="input_fastq" type="file" accept=".fastq, .gz" multiple webkitdirectory="true" directory="true"
                    ref={el => fileRef.current['fastq'] = el}
                    onChange={onChange}
                />
            } */}
            {/* <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['fastq'].click()}>{`Choose Fastq ${selector}`}</Button>
            <input type="file" multiple accept=".fastq, .gz" ref={el => fileRef.current['fastq'] = el} />
            <button type="submit" onClick={fileSubmit} >Start Upload</button>
            {fastqName === '' ? null : fastqName.length >= 2 ? `${fastqName.length} Files...` : fastqName[0].name}
            <Button className="sumbit" type="submit" onClick={fileSubmit} >Start Upload</Button> */}
            {/* <p className='helpful' >Contact us at support@bioinfox.com to upload Fastq files</p>
            </div>
            <br></br> */}
            {/* <br></br> */}
            {/* <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Fasta Sequence File to Pipeline</h3> */}
            {/* <input className="input_seq" type="file" accept=".fasta, .fas, .fa" ref={el => fileRef.current['sequences'] = el} onChange={onChangeSeq} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['sequences'].click()} >Choose Fasta File</Button>
            {fastaName === '' ? null : fastaName.name}
            <Button className="submit" type="submit" onClick={fileSeqSubmit} >Start Upload</Button> */}
            {/* <p className='helpful' >Contact us at support@bioinfox.com to upload Fasta files</p> */}
            {/* <button type="submit" onClick={fileSeqSubmit} >Start Upload</button> */}
            {/* <br></br>
            <br></br> */}
            <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Sample Metadata File</h3>
            <input className="input_client" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['client'] = el} onChange={onChangeClient} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['client'].click()} >Choose File</Button>
            {clientName === '' ? null : clientName.name}
            <Button className="submit" type="submit" onClick={fileClientSubmit} >Start Upload</Button>
            {/* <button type="submit" onClick={fileClientSubmit} >Start Upload</button> */}
            {/* <p>Download Template File</p> */}
            <br></br>
            <br></br>
            <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Quality Metadata File</h3>
            <input className="input_add" type="file" accept=".csv, .xls, .xlsx" ref={el => fileRef.current['add'] = el} onChange={onChangeAdd} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['add'].click()} >Choose File</Button>
            {addName === '' ? null : addName.name}
            <Button className="submit" type="submit" onClick={fileAddSubmit} >Start Upload</Button>
            <br></br>
            <br></br>
            {/* <br></br> */}
            <h3 style={{ fontFamily: "Avenir", fontWeight: "bold" }} >Upload Sequence Metadata</h3>
            <input className="input_seq_meta" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['seqMeta'] = el} onChange={onChangeSeqMeta} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['seqMeta'].click()} >Choose File</Button>
            {seqMeta === '' ? null : seqMeta.name}
            <Button className="submit" type="submit" onClick={fileSeqMetaSubmit} >Start Upload</Button>
            <br/>
            <br/>
            <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Resistance Metadata File</h3>
            <input className="input_add" type="file" accept=".csv, .xls, .xlsx" ref={el => fileRef.current['resMeta'] = el} onChange={onChangeRes} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['resMeta'].click()} >Choose File</Button>
            {resMeta === '' ? null : resMeta.name}
            <Button className="submit" type="submit" onClick={fileResMetaSubmit} >Start Upload</Button>
            <br></br>
            <br />
            <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Antibiotics Metadata File</h3>
            <input className="input_add" type="file" accept=".csv, .xls, .xlsx" ref={el => fileRef.current['amrMeta'] = el} onChange={onChangeAMR} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['amrMeta'].click()} >Choose File</Button>
            {AMRMeta === '' ? null : AMRMeta.name}
            <Button className="submit" type="submit" onClick={fileAMRSubmit} >Start Upload</Button>
            <br></br>
            {/* {showProgress ?
                <ProgressBar className="progress_bar" now={progress} label={`${progress}%`} /> : null
            } */}
        </div>
    )
}

export default UploadFiles;