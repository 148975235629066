import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react'
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <Auth0Provider
    domain="bioinfox.auth0.com"
    clientId="7y8qbEoVbfRNnPeo6r1fayX5vHNopvHR"
    // domain="dev-p90qsvv0.us.auth0.com"
    // clientId="mu0bjn8xrto0P8mbFa8kn7zhpXKuhLSZ"
    redirectUri={window.location.origin}
    audience="https://foxseq.com"
    // scope="read:current_user update:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
