import React, { useEffect } from 'react'
import QuickStart from '../components/Visualizations/QuickStart/QuickStart';
import { getProject } from '../Helpers';
import Tableau from '../components/Visualizations/Tableau/Tableau';
// import { Tab } from 'semantic-ui-react';
import UploadFiles from '../components/Visualizations/UploadFiles/UploadFiles';
import DownloadFiles from '../components/Visualizations/DownloadFiles/DownloadFiles';
import UploadBacteria from '../components/Visualizations/UploadFiles/UploadBacteria';
import DownloadBacteria from '../components/Visualizations/DownloadFiles/DownloadBacteria';
import { useAuth0 } from '@auth0/auth0-react';

let urls = {}
export function getUrls(urlObj) {
  // console.log(urlObj);
  for (let key in urlObj.urls) {
    urls[key] = urlObj.urls[key];
  }
  // console.log(urls);
}

function Pages({ viz, project, dataType }) {
  // const [vis, setVis] = useState(viz);
  // console.log(project);
  const {getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    // setVis(viz)
    if (project !== null) {
      let token;
      (async () => {
        token = await getAccessTokenSilently();
        getProject(project, token);
      })()
    }
  }, [project, getAccessTokenSilently]);

  return (
    <div className="pages">
      <QuickStart
        show={viz === 'home'}
        key="home"
      />
      {urls.quickStart && viz === 'quickStart' ?
        <Tableau
          show={viz === 'quickStart'}
          url={urls.quickStart}
          key="quickStart"
        /> : null
      }
      {urls.local && viz === 'tableau' ?
        <Tableau
          show={viz === 'tableau'}
          url={urls.local}
          key='tableau'
        /> : null
      }
      {urls.usaTracker && viz === 'usaTracker' ?
        <Tableau
          show={viz === 'usaTracker'}
          url={urls.usaTracker}
          key="usaTracker"
        /> : null
      }
      {urls.globalTracker && viz === 'globalTracker' ?
        <Tableau
          show={viz === 'globalTracker'}
          url={urls.globalTracker}
          key="globalTracker"
        /> : null
      }
      {urls.usaDash && viz === 'usaDash' ?
        <Tableau 
          show={viz === 'usaDash'}
          url={urls.usaDash}
          key="usaDash"
        /> : null
      }
      {urls.global && viz === 'global' ?
        <Tableau 
          show={viz === 'global'}
          url={urls.global}
          key="global"
        /> : null
      }
      {project && viz === 'upload' ?
        <UploadFiles 
          show={viz === 'upload'}
          hospital={project.name}
          admin={project.admin}
          key="upload"
        /> : null
      }
      {project && viz === 'download' ?
        <DownloadFiles 
          show={viz === 'download'}
          key="download"
          hospital={project.name}
          admin={project.admin}
          dataType={dataType}
        /> : null
      }
      {project && viz === 'microbiome' ?
        <Tableau 
          show={viz === 'microbiome'}
          url={urls.microbiome}
          key="microbiome"
        /> : null
      }
      {project && viz === 'bacteria' ?
        <Tableau 
          show={viz === 'bacteria'}
          url={urls.bacteria}
          key="bacteria"
        /> : null
      }
      {project && viz === 'uploadBac' ?
        <UploadBacteria 
          show={viz === 'uploadBac'}
          hospital={project.name}
          key='uploadBac'
        /> : null
      }
      {project && viz === 'downloadBac' ?
        <DownloadBacteria 
          show={viz === 'downloadBac'}
          key="downloadBac"
          hospital={project.name}
          admin={project.admin}
          dataType={dataType}
        /> : null
      }
      {urls.resistance && viz === 'resistance' ?
        <Tableau 
          show={viz === 'resistance'}
          url={urls.resistance}
          key="resistance"
        /> : null
      }
      {urls.typingDash && viz === 'typingDash' ?
        <Tableau
          show={viz === 'typingDash'}
          url={urls.typingDash}
          key="typingDash"
        /> : null
      }
      {urls.distance && viz === 'distance' ?
        <Tableau 
          show={viz === 'distance'}
          url={urls.distance}
          key="distance"
        /> : null
      }
    </div>
  )
}

export default Pages
