import React from 'react'
import './Footer.css';
import Copyright from './Copyright';
import { Popup, Icon } from 'semantic-ui-react'

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

function Footer({ setViz, viz, dataType, project }) {
  // const [visualization, setVisualization] = useState(viz);
  

  // const prevVisualization = usePrevious(viz);

  // useEffect(() => {
  //   if (viz !== prevVisualization) {
  //     setVisualization(viz);
  //   }
  //   setViz(visualization);
  // }, [visualization, setViz, viz, prevVisualization]);

  // const changeVis = (vis) => {
  //     console.log(vis);
  //     setViz(vis);
  // }

  return (
    <div className="footer" >
      <div className="utilContainer" >
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="mailto:support@bioinfox.com" id="support_email" style={{ display: 'none' }} />
        <Popup
          trigger={
            <div onClick={() => document.getElementById('support_email').click()} style={{ cursor: "pointer" }}>
              <Icon size="big" style={{ color: "rgb(216, 216, 216" }} name="help circle" />
            </div>
          }
          content="Contact Us"
        />
        {dataType === 'Virus' ?
          <Popup
            trigger={
              <div onClick={() => setViz('upload')} style={{ borderBottom: `${viz === "upload" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer", marginRight: '5px' }}>
                <Icon size="big" style={{ color: viz === "upload" ? "rgb(78, 146, 223" : "rgb(216, 216, 216" }} name="cloud upload" />
              </div>
            }
            content="Upload Files to AWS"
          /> : null
        }
        {dataType === 'Bacteria' ?
          <Popup
            trigger={
              <div onClick={() => setViz('uploadBac')} style={{ borderBottom: `${viz === "uploadBac" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer", marginRight: '5px' }}>
                <Icon size="big" style={{ color: viz === "uploadBac" ? "rgb(78, 146, 223" : "rgb(216, 216, 216" }} name="cloud upload" />
              </div>
            }
            content="Upload Files to AWS"
          /> : null
        }
        {dataType === 'Virus' ?
          <Popup
            trigger={
              <div onClick={() => setViz('download')} style={{ borderBottom: `${viz === "download" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }} >
                <Icon size="big" style={{ color: viz === "download" ? "rgb(78, 146, 223" : "rgb(216, 216, 216" }} name="cloud download" />
              </div>
            }
            content="Download Files"
          /> : null
        }
        {dataType === 'Bacteria' ?
          <Popup 
            trigger={
              <div onClick={() => setViz('downloadBac')} style={{ borderBottom: `${viz === "downloadBac" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }} >
                <Icon size="big" style={{ color: viz === "downloadBac" ? "rgb(78, 146, 223" : "rgb(216, 216, 216" }} name="cloud download" />
              </div>
            }
            content="Download Files"
          /> : null
        }
      </div>
      <Copyright />
      <div className="switchButtonContainer" >
        <Popup
          trigger={
            <div onClick={() => setViz('home')} style={{ borderBottom: `${viz === "home" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
              <Icon size="big" style={{ width: 30, height: 30, color: viz === "home" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="home" />
            </div>
          }
          content="Home"
        />
        
          <Popup
            trigger={
              <div onClick={() => setViz("quickStart")} style={{ borderBottom: `${viz === "quickStart" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "quickStart" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="quora" />
              </div>
            }
            content="QuickStart"
          /> 
        {dataType === 'Virus' && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz("tableau")} style={{ borderBottom: `${viz === "tableau" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "tableau" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="dna" />
              </div>
            }
            content="Mutation Tracker"
          /> : null
        }
        {/* {dataType === 'Virus' ?
          <Popup
            trigger={
              <div onClick={() => setViz("usaTracker")} style={{ borderBottom: `${viz === "usaTracker" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "usaTracker" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="globe" />
              </div>
            }
            content="USA Mutation Tracker"
          /> : null
        }
        {dataType === 'Virus' ?
          <Popup
            trigger={
              <div onClick={() => setViz("globalTracker")} style={{ borderBottom: `${viz === 'globalTracker' ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "globalTracker" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="globe" />
              </div>
            }
            content="Global Mutation Tracker"
          /> : null
        } */}
        {dataType === 'Virus' && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz("usaDash")} style={{ borderBottom: `${viz === "usaDash" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "usaDash" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="dashboard" />
              </div>
            }
            content="USA COVID-19 Dashboard"
          /> : null
        }
        {dataType === 'Virus' && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz("global")} style={{ borderBottom: `${viz === "global" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "global" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="dashboard" />
              </div>
            }
            content="USA Mutations Dashboard"
          /> : null
        }
        {dataType === 'Bacteria' && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz("bacteria")} style={{ borderBottom: `${viz === "bacteria" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "bacteria" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="dna" />
              </div>
            }
            content="Cluster Dashboard"
          /> : null
        }
        {dataType === 'Bacteria' && project.tableau === true ?
          <Popup 
            trigger={
              <div onClick={() => setViz("distance")} style={{ borderBottom: `${viz === "distance" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "distance" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="chart line" />
              </div>
            }
            content="Distance Dashboard"
          /> : null
        }
        {dataType === 'Bacteria' && project.tableau === true ?
          <Popup 
          trigger={
            <div onClick={() => setViz('typingDash')} style={{ borderBottom: `${viz === 'typingDash' ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }} >
                <Icon size="big" style={{ width: 30, height: 30, color: viz === 'typingDash' ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="flask" />
              </div>
            }
            content="Typing Dashboard"
            /> : null
          }
        {dataType === "Bacteria" && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz('resistance')} style={{ borderBottom: `${viz === 'resistance' ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }} >
                <Icon size="big" style={{ width: 30, height: 30, color: viz === 'resistance' ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name='pills' />
              </div>
            }
            content="Resistance Dashboard"
          /> : null
        }
        {dataType === 'Bacteria' && project.tableau === true ?
          <Popup
            trigger={
              <div onClick={() => setViz("microbiome")} style={{ borderBottom: `${viz === "microbiome" ? "solid 2px rgb(146, 187, 102)" : "none"}`, cursor: "pointer" }}>
                <Icon size="big" style={{ width: 30, height: 30, color: viz === "microbiome" ? "rgb(78, 146, 223)" : "rgb(216, 216, 216)" }} name="chart bar" />
              </div>
            }
            content="Microbiome"
          /> : null
        }
      </div>
    </div>
  )
}

export default Footer
